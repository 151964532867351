import { useApi } from '@tenant/composables'

export type ContactDetailTypesState = {
    contactDetailTypes: string[]
}

export default {
    state: (): ContactDetailTypesState => ({
        contactDetailTypes: [],
    }),

    mutations: {
        setContactDetailTypes(
            state: ContactDetailTypesState,
            contactDetailTypes: []
        ) {
            state.contactDetailTypes = contactDetailTypes
        },
    },

    getters: {
        contactDetailTypes(state: ContactDetailTypesState) {
            return state.contactDetailTypes
        },
    },

    actions: {
        fetchContactDetailTypes({
            commit,
        }: {
            commit: (...args: any[]) => void
        }) {
            const { execute } = useApi('/api/contact-detail-types', 'GET')

            execute().then((response) => {
                const contactDetailTypes = response.map(
                    (salutation: { id: string; name: string }) => ({
                        value: salutation.id,
                        label: salutation.name,
                    })
                )

                commit('setContactDetailTypes', contactDetailTypes)
            })
        },
    },
}
